import React from "react";
import { Container, Row, Col, Image, CardBody } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/contact-bg.jpeg";
import custom from "../../img/services/customAPPevelopment​.svg";

export default function Custom() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Custom Software Development" />
      <section>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <h2>
              Your Tailor-Made Software Development Partner
              </h2>
              <p><b>We craft bespoke software solutions tailored to your specific requirements and workflows. Unlike off-the-shelf software, our custom approach ensures seamless integration with your existing systems, addresses your unique challenges, and empowers your team with the functionalities they need to thrive.</b></p>
              <p>In today's dynamic business landscape, off-the-shelf solutions often fall short. Softagics empowers you to bridge the gap with custom software development that perfectly aligns with your unique needs and goals.</p>
            </Col>
            <Col xs={12} md={6}>
              <Image src={custom} fluid alt="" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h5>Here's how we can transform your vision into a reality:</h5>
              <ul>
                <li><b>In-depth Needs Assessment:</b> We don't just build software; we become an extension of your team. Through collaborative workshops and discussions, we gain a comprehensive understanding of your specific challenges and objectives.</li>
                <li><b>Bespoke Solutions:</b> One-size-fits-all software simply doesn't cut it. We craft custom solutions tailored to your workflows, industry regulations, and growth aspirations.</li>
                <li><b>Future-Proof Technology:</b> Leveraging cutting-edge technologies, we build software solutions that are scalable, secure, and adaptable to evolving business needs.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h5>Benefits of Choosing Softagics for Custom Software Development:</h5>
              <ul>
                <li><b>Enhanced Efficiency:</b> Automate manual tasks, streamline processes, and boost overall operational efficiency.</li>
<li><b>Improved Productivity:</b> Empower your team with the right tools to maximize productivity and achieve more.</li>
<li><b>Competitive Advantage:</b> Gain a strategic edge by leveraging software solutions specifically designed to address your industry challenges.</li>
<li><b>Measurable ROI:</b> Our solutions deliver tangible results**, providing a clear return on your investment.</li>

              </ul>
              
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
