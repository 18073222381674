import React from "react";
import { Image, Tab, Tabs, Col, Container, Row } from "react-bootstrap";
import jadeedmunshi from "../img/jadeedmunshib.png";
import jadeedems from "../img/jadeedems.png";
import shangrilla from "../img/shangrilla.png";
import finead from "../img/finead.png";
import cityCollege from "../img/cityCollege.png";
import qmatic from "../img/qmatic.png";
import SectionTitle from "./SectionTitle";
export default function Projects() {
  const webAppsArray = [
    {
      title: "Financial Management System",
      imageSrc: jadeedmunshi, // Assuming jadeedmunshi is a variable containing image source
      alt: "Financial Management System",
      description:
        "Tempor ipsum dolor sit amet loremimsu, consectetuiscing elit, sed do eiusmod.",
        delay: "100",
    },
    {
      title: "Queue Management System",
      imageSrc: qmatic, // Assuming jadeedmunshi is a variable containing image source
      alt: "Queue Management System",
      delay: "200",
    },
    {
      title: "City College",
      imageSrc: cityCollege, // Assuming jadeedmunshi is a variable containing image source
      alt: "City College",
      delay: "300",
    },
    {
      title: "Hostel Management System",
      imageSrc: jadeedmunshi, // Assuming jadeedmunshi is a variable containing image source
      alt: "Hostel Management System",
      delay: "400",
    },
  ];
  const mobileAppsArray = [
    {
      title: "Shangrila Feedback",
      imageSrc: shangrilla, // Assuming jadeedmunshi is a variable containing image source
      alt: "Shangrila Feedback",
      delay: "100",
    },
    {
      title: "Attari Sales Ledger",
      imageSrc: jadeedmunshi, // Assuming jadeedmunshi is a variable containing image source
      alt: "Attari Sales Ledger",
      delay: "200",
    },
    {
      title: "Finead Survey",
      imageSrc: finead, // Assuming jadeedmunshi is a variable containing image source
      alt: "Finead Survey",
      delay: "300",
    },
    {
      title: "City College",
      imageSrc: cityCollege, // Assuming jadeedmunshi is a variable containing image source
      alt: "Jadeed Education Management System Thumbnail",
      delay: "400",
    },
  ];
  const desktopAppsArray = [
    {
      title: "Shangrila Feedback",
      imageSrc: shangrilla, // Assuming jadeedmunshi is a variable containing image source
      alt: "Shangrila Feedback",
      delay: "100",
      description:
        "Tempor ipsum dolor sit amet loremimsu, consectetuiscing elit, sed do eiusmod.",
    },
    {
      title: "Attari Sales Ledger",
      imageSrc: jadeedmunshi, // Assuming jadeedmunshi is a variable containing image source
      alt: "Attari Sales Ledger",
      delay: "200",
    },
    {
      title: "Finead Survey",
      imageSrc: finead, // Assuming jadeedmunshi is a variable containing image source
      alt: "Finead Survey",
      delay: "300",
    },
  ];
  const designArray = [
    {
      title: "Shangrila Feedback",
      imageSrc: shangrilla, // Assuming jadeedmunshi is a variable containing image source
      alt: "Shangrila Feedback",
      delay: "100",
    },
    {
      title: "Attari Sales Ledger",
      imageSrc: jadeedmunshi, // Assuming jadeedmunshi is a variable containing image source
      alt: "Attari Sales Ledger",
      delay: "200",
    },
    {
      title: "Finead Survey",
      imageSrc: finead, // Assuming jadeedmunshi is a variable containing image source
      alt: "Finead Survey",
      delay: "300",
    },
    {
      title: "City College",
      imageSrc: cityCollege, // Assuming jadeedmunshi is a variable containing image source
      alt: "City College",
      delay: "400",
    },
    
  ];

  return (
    <section className="projects" data-aos="fade-up">
      <Container>
        <SectionTitle caption={"Project"} title="Explore Our Latest Endeavors" desc={"There are various successful projects from IT strategy consulting to the end-to-end development of scalable solutions made by our creative and experienced IT professionals."} />
        <Row className="mb-3">
          <Col xs={12} md={12}>
            <Tabs defaultActiveKey="web" id="fill-tab-example" fill>
              <Tab eventKey="web" title="Web Apps">
                <Row className="justify-content-center">
                  {webAppsArray.map((item, index) => (
                    <Col
                      key={index}
                      xs={12}
                      md={3}
                      className="d-flex align-items-stretch mb-4" data-aos="zoom-in" data-aos-delay={item.delay}
                    >
                      <div
                        class="icon-box hvr-float-shadow"
                      >
                        <div className="thumbnail">
                          <Image src={item.imageSrc} alt={item.alt} />
                        </div>
                        <div className="box-body">
                          <h5>{item.title}</h5>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Tab>
              {/* Mobile Apps */}
              <Tab eventKey="mobile" title="Mobile Apps">
                <Row className="justify-content-center">
                  {mobileAppsArray.map((item, index) => (
                    <Col
                      key={index}
                      xs={12}
                      md={3}
                      className="d-flex align-items-stretch mb-4" data-aos="zoom-in" data-aos-delay={item.delay}
                    >
                      <div
                        class="icon-box"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div className="thumbnail">
                          <Image src={item.imageSrc} alt={item.alt} />
                        </div>
                        <div className="box-body">
                          <h5>{item.title}</h5>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Tab>
              {/* Desktop Apps */}
              <Tab eventKey="desktop" title="Desktop Apps">
                <Row className="justify-content-center">
                  {desktopAppsArray.map((item, index) => (
                    <Col
                      key={index}
                      xs={12}
                      md={3}
                      className="d-flex align-items-stretch mb-4" data-aos="zoom-in" data-aos-delay={item.delay}
                    >
                      <div
                        class="icon-box"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div className="thumbnail">
                          <Image src={item.imageSrc} alt={item.alt} />
                        </div>
                        <div className="box-body">
                          <h5>{item.title}</h5>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Tab>
              {/* UIUX Apps */}
              <Tab eventKey="design" title="UI/UX Design">
                <Row className="justify-content-center">
                  {designArray.map((item, index) => (
                    <Col
                      key={index}
                      xs={12}
                      md={3}
                      className="d-flex align-items-stretch mb-4"
                    >
                      <div
                        class="icon-box"
                        data-aos="fade-up"
                        data-aos-delay="300"
                      >
                        <div className="thumbnail">
                          <Image src={item.imageSrc} alt={item.alt} />
                        </div>
                        <div className="box-body">
                          <h5>{item.title}</h5>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
