import React from "react";
import {
  Row,
  Col,
  Image,
  Button,
  Container,
  Card,
  CardBody,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "./InnerHeader";
import headerbg from "../img/contact-bg.jpeg";
import Skills from "./Skills";
import ClientSlider from "./ClientSlider";
import Counter from "./Counter";
import director from "../img/director.jpg";
import handshake from "../img/handshake.jpg";
import ServicesList from './ServicesList';
const whyUs = [
  {
    bold: "One-Stop Shop:",
    title:
      "We provide a comprehensive suite of services, eliminating the need to manage multiple vendors.",
  },
  {
    bold: "Experienced Team:",
    title:
      "Our team of qualified professionals possesses the skills and knowledge to deliver exceptional results.",
  },
  {
    bold: "Custom-Tailored Solutions:",
    title:
      "We take the time to understand your unique business needs and develop solutions that perfectly align with your goals.",
  },
  {
    bold: "Focus on Innovation:",
    title:
      "We stay ahead of the curve by constantly exploring new technologies and trends to ensure your solutions remain competitive.",
  },
  {
    bold: "Commitment to Quality:",
    title:
      "We deliver high-quality work that meets your expectations and exceeds industry standards.",
  },
];
export default function Company() {
  return (
    <>
      <Header backgroundImage={headerbg} title="About Company" />
      <section data-aos="fade-up">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={7} className="mb-3" data-aos="fade-up" data-aos-delay="100">
              <h1>Director Message</h1>
              <h5>Welcome to the Softagics!</h5>
              <p>
                I'm thrilled to connect with you today and share our passion for
                creating innovative software solutions. At Softagics, we believe
                in the power of technology to transform businesses and empower
                individuals.
              </p>
              <p>
                Our team is comprised of talented and dedicated individuals who
                are driven by a shared vision: to craft exceptional software
                that exceeds expectations. We foster a culture of creativity,
                collaboration, and continuous learning, ensuring we stay at the
                forefront of the ever-evolving tech landscape.
              </p>
              <p>
                Whether you're a seasoned developer seeking a new challenge or a
                business owner searching for a reliable software partner, we
                invite you to explore what Softagics has to offer. We're
                committed to building strong relationships and delivering
                solutions that drive real results.
              </p>
              <p>
                We're always excited to hear from passionate individuals and
                businesses who share our enthusiasm for technology. Feel free to
                browse our website, learn more about our services, and don't
                hesitate to reach out if you have any questions.
              </p>
            </Col>
            <Col xs={12} md={5} data-aos="fade-left" data-aos-delay="100">
              <Card>
                <CardBody>
                  <Image src={director} alt="Director Softagics" fluid />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section data-aos="fade-up">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <div className="section-title text-center">
                <h1>Your One-Stop Shop for Digital Success</h1>
                <p>
                  Softagics is a full-service software house in Multan,
                  Pakistan, dedicated to empowering businesses with innovative
                  and effective digital solutions.
                </p>
                <Link to="/Contact">
                  <Button variant="primary" size="lg">
                    Contact With Us
                    <i className="fa-solid fa-arrow-right"></i>
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section data-aos="fade-up">
        <Container>
          <Row className="align-items-center">
            <Col xs={12} md={7} data-aos="fade-up" data-aos-delay="100">
              <h5>Why Choose Softagics?</h5>
              <ul>
                {whyUs.map((list, index) => (
                  <li key={index}>
                    <b>{list.bold}</b> {list.title}
                  </li>
                ))}
              </ul>
            </Col>
            <Col xs={12} md={5} className="whyus" data-aos="fade-left" data-aos-delay="100">
              <Card>
                <CardBody>
                  <Image src={handshake} fluid alt="Why Softagics" />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section data-aos="fade-up">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8}>
              <div className="section-title text-center">
                <h1>List Of Services</h1>
                <p>
                  We offer a comprehensive range of services to cater to all
                  your technological needs:
                </p>
              </div>
            </Col>
          </Row>
          <ServicesList/>
        </Container>
      </section>

      <ClientSlider />
      <Counter />
      <Skills />
    </>
  );
}
