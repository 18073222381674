import React from "react";
import {Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
const data = [
  {
    icon: "fa-solid fa-code",
    title: "Web Development",
    delay: "100",
    animation: "zoom-in",
  },
  {
    icon: "fa-solid fa-mobile-screen",
    title: "Mobile Development​",
    delay: "200",
    animation: "zoom-in",
  },
  {
    icon: "fas fa-luggage-cart",
    title: "E-Commerce Solution​",
    delay: "300",
    animation: "zoom-in",
  },
  {
    icon: "fa-solid fa-pen-ruler",
    title: "UI/UX Design​​",
    delay: "400",
    animation: "zoom-in",
  },
];
export default function Services() {
  return (
    <section className="services" data-aos="fade-up">
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} md={6}>
            <Row>
              <div className="section-title">
                <h2>Services</h2>
                <h1>
                  The Perfect Services We’re Offering
                </h1>
                <p>
                  We offers a full-cycle software development services that meet
                  varied business requirements from IT strategy consulting to
                  the end-to-end development of scalable solutions.
                </p>
                <Link to="/Services">
                  <Button variant="primary" size="lg">View All Services <i class="fa-solid fa-arrow-right"></i></Button>
                </Link>
              </div>
            </Row>
          </Col>
          <Col xs={12} md={6}>
            <Row>
              {data.map((item, index) => (
                <Col key={index} xs={12} md={6} className="mt-4 setvice-item"  data-aos={item.animation} data-aos-delay={item.delay}>
                  <div className="setvice-item-bg">
                    <div className="setvice-item-body">
                      <div className="icon-box">
                        <div className="icon">
                          <i className={item.icon}></i>
                        </div>
                        <h4>
                          <a href="">{item.title}</a>
                        </h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
