import React, { useEffect } from 'react';
import "./App.css";
import "./Hover.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";
import BackToTopButton from "./components/BackToTopButton";
import Home from "./components/Home";
import Contact from "./components/Contact";
import Solutions from "./components/Solutions";
// Services
import Services from "./components/Services";
import ServicesList from "./components/ServicesList";
import ServicesHome from "./components/ServicesHome";
import Web from "./components/services/Web";
import Software from "./components/services/Software";
import Desktop from "./components/services/Desktop";
import Mobile from "./components/services/Mobile";
import Custom from "./components/services/Custom";
import Commerce from "./components/services/Commerce";
import UIUX from "./components/services/Uiux";
import Graphic from "./components/services/Graphic";
import Internet from "./components/services/Internet";
import Casestudies from "./components/Casestudies";
import Clients from "./components/Clients";
import Careers from "./components/Careers";
import Company from "./components/Company";
import LookingForBest from "./components/LookingForBest";
import Projects from "./components/Projects";
import Products from "./components/Products";
import ProductsHome from "./components/ProductsHome";
import Counter from "./components/Counter";
import About from "./components/About";
import Privacy_Policy_Jems from "./components/Privacy_Policy_Jems";

import NotFoundPage from './components/NotFoundPage';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease', // Easing function
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <>
      <BrowserRouter>
        <Header />
        <div className="content-body">
          <Routes>
            <Route index element={<Home />} />
            <Route path="/Company" element={<Company />} />
            <Route path="/About" element={<About />} />
            <Route path="/Services" element={<Services />} />
            <Route path="/ServicesList" element={<ServicesList />} />
              <Route path="/ServicesHome" element={<ServicesHome />} />
              <Route path="/Web" element={<Web />} />
              <Route path="/Software" element={<Software />} />
              <Route path="/Desktop" element={<Desktop />} />
              <Route path="/Mobile" element={<Mobile />} />
              <Route path="/Custom" element={<Custom />} />
              <Route path="/Commerce" element={<Commerce />} />
              <Route path="/UIUX" element={<UIUX />} />
              <Route path="/Internet" element={<Internet />} />
              <Route path="/Graphic" element={<Graphic />} />
              {/* Add more nested routes as needed */}
            <Route path="/Solutions" element={<Solutions />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Casestudies" element={<Casestudies />} />
            <Route path="/Clients" element={<Clients />} />
            <Route path="/Careers" element={<Careers />} />
            <Route path="/Projects" element={<Projects />} />
            <Route path="/ProductsHome" element={<ProductsHome />} />
            <Route path="/Products" element={<Products />} />
            <Route path="/Counter" element={<Counter />} />
            <Route path="/Privacy_Policy_Jems" element={<Privacy_Policy_Jems />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <LookingForBest />
        <Footer />
        <BackToTopButton />
      </BrowserRouter>
    </>
  );
}

export default App;
