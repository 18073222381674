import React from "react";
import { Row, Container, Image, Col, Card, CardBody } from "react-bootstrap";
import about from "../img/about.jpg";
import Skills from "./Skills";

export default function About() {
  return (
    <>
    <section className="about" data-aos="fade-up">
      <Container className="container">
        <Row>
          <Col lg={6} className="about-img" data-aos="fade-right" data-aos-delay="100">
            <Image src={about} fluid alt="" />
          </Col>
          <Col
            lg={6}
            className="pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
            data-aos="fade-up" data-aos-delay="100"
          >
            <div className="section-title">
              <h2>About Company</h2>
              <h1>
                Find Out More <span>About Us</span>
              </h1>
              <p>
              At Softagics, we specialize in crafting cutting-edge solutions in
              Web Development, Software Development, and Mobile Application
              Development. Catering to a global clientele, we are committed to
              delivering comprehensive information technology services coupled
              with strategic marketing consultations. Our mission is to empower
              businesses in their pursuit of success by formulating diverse
              policies and strategies tailored to their unique needs.
            </p>
            </div>
            <Row>
              <Col md={6} sm={12} className="mb-2 card-item">
                <h5>Expert Team Member</h5>
                <p>
                Experienced professionals delivering exceptional results.
                </p>
              </Col>
              <Col md={6} sm={12} className="mb-2 card-item">
                <h5>The Best Services</h5>
                <p>
                Top-notch solutions tailored to exceed your expectations.
                </p>
              </Col>
            </Row>
            <p>
            Softagics is a full-service software house in Pakistan, dedicated to empowering businesses with innovative and effective digital solutions. 
            </p>
          </Col>
        </Row>
      </Container>
    </section>
    
    <Skills/>
    </>
  );
}
