import React from 'react'
import { Container, Row, Col, Image, CardBody } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/contact-bg.jpeg";
import mobile from "../../img/services/mobileAPPdevelopment​.svg";
export default function Mobile() {
  return (
    <>
    <Header backgroundImage={headerbg} title="Mobile App Development" />
    <section>
      <Container>
        <Row className="justify-content-center align-items-center">
          <Col xs={12} md={6}>
            <h2>
            Powering Your Business on the Go
            </h2>
            <p>
              <b>
              In today's mobile-first world, having a high-quality mobile app is essential for businesses of all sizes. Softagics is a leading provider of mobile app development services, with a team of experienced developers who can create custom apps to meet your specific needs.
              </b>
            </p>
            <p>Whether you're looking for a simple app to showcase your products or services, or a complex app with advanced features, Softagics can help. Their developers have expertise in a wide range of mobile technologies, including iOS, Android, and React Native. This allows them to create apps that are not only functional but also beautiful and user-friendly.</p>
          </Col>
          <Col xs={12} md={6}>
            <Image src={mobile} fluid alt="" />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <h5>In addition to development, Softagics also offers a number of other services, such as:</h5>
            <ul>
              <li>User interface (UI) and user experience (UX) design</li>
<li>App maintenance and support</li>
<li>App marketing and promotion</li>

            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12}>
            <h5>
            Why Choose Softagics for Your Mobile App Development Needs?
            </h5>
            <p>There are many reasons to choose Softagics for your mobile app development project. Here are just a few:</p>
            <ul>
              <li><b>Experienced team:</b> Softagics has a team of experienced developers who are passionate about creating high-quality mobile apps.</li>
<li><b>Focus on quality:</b> Softagics is committed to delivering high-quality apps that meet your specific needs.</li>
<li><b>Wide range of services:</b> Softagics offers a wide range of services, from development to marketing, to help you get your app off the ground.</li>
<li><b>Competitive pricing:</b> Softagics offers competitive pricing for their services.</li>
            </ul>
            <h5>Get Started Today</h5>
            <p>If you're ready to start developing your mobile app, contact Softagics today for a free consultation. They will work with you to understand your needs and develop a custom app that will help you achieve your business goals.</p>
          </Col>
        </Row>
      </Container>
    </section>
  </>
  )
}
