import React from "react";
import { Row, Col, Container } from "react-bootstrap";
export default function WayofWork() {
  return (
    <>
      <section data-aos="fade-up" id="featured-services" class="featured-services">
        <Container class="container">
          <Row className="justify-content-center">
            <Col xs={12} md={4} className="d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
                <div class="icon">
                <i class="fa-solid fa-award"></i>
                </div>
                <h5>Great Experiences</h5>
                <p>Tempor ipsum dolor sit amet loremimsu, consectetuiscing elit, sed do eiusmod.</p>
              </div>
            </Col>

            <Col xs={12} md={4} className="d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
                <div class="icon">
                <i class="fa-solid fa-trophy"></i>
                </div>
                <h5>Reference for Result</h5>
                <p>Tempor ipsum dolor sit amet loremimsu, consectetuiscing elit, sed do eiusmod.</p>
              </div>
            </Col>

            <Col xs={12} md={4} className="d-flex align-items-stretch mb-5 mb-lg-0">
              <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
                <div class="icon">
                <i class="fa-solid fa-users"></i>
                </div>
                <h5>Experience Team</h5>
                <p>Tempor ipsum dolor sit amet loremimsu, consectetuiscing elit, sed do eiusmod.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
