import React, { useState, useEffect } from 'react';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import logo from '../logo.svg';
import Topbar from'../components/Topbar'

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Clean up by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []); // Empty dependency array ensures that this effect runs only once, similar to componentDidMount
  return (
    <>
    <Topbar/>
    <Navbar expand="lg" className={isScrolled ? 'fixed-top' : ''}>
      <Container>
        <Navbar.Brand as={NavLink} to="/">
          <img src={logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={NavLink} to="/Company"onClick={() => window.scrollTo(0, 0)}>Company</Nav.Link>
            <NavDropdown title="Services" id="basic-nav-dropdown">
              <NavDropdown.Item as={NavLink} to="/Software"onClick={() => window.scrollTo(0, 0)}>Software Development</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Web"onClick={() => window.scrollTo(0, 0)}>Web Development</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Mobile"onClick={() => window.scrollTo(0, 0)}>Mobile App Development</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Custom"onClick={() => window.scrollTo(0, 0)}>Custom App Development</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/UIUX"onClick={() => window.scrollTo(0, 0)}>UI/UX Design</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Graphic"onClick={() => window.scrollTo(0, 0)}>Graphic Designing</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Commerce"onClick={() => window.scrollTo(0, 0)}>E-Commerce Solutions</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Internet"onClick={() => window.scrollTo(0, 0)}>Internet Marketing</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={NavLink} to="/Products"onClick={() => window.scrollTo(0, 0)}>Products</Nav.Link>
            {/* <NavDropdown title="Solutions" id="basic-nav-dropdown">
              <NavDropdown.Item as={NavLink} to="/Financial">Financial Management System</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Education">Education Management System</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Queue">Queue Management System</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Counter">Open WiFi Counter</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Hostel">Hostel Management System</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/Feedback">Feedback Management System</NavDropdown.Item>
              <NavDropdown.Item as={NavLink} to="/B2B">B2B Management System</NavDropdown.Item>
            </NavDropdown> */}
            {/* <Nav.Link as={NavLink} to="/Casestudies"onClick={() => window.scrollTo(0, 0)}>Case Studies</Nav.Link> */}
            <Nav.Link as={NavLink} to="/Clients"onClick={() => window.scrollTo(0, 0)}>Clients</Nav.Link>
            {/* <Nav.Link as={NavLink} to="/Careers"onClick={() => window.scrollTo(0, 0)}>Careers</Nav.Link> */}
            <Nav.Link as={NavLink} to="/Contact"onClick={() => window.scrollTo(0, 0)}>Contact</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
}
