import React, { useState, useEffect } from 'react';
import {Image, Col, Container, Row } from 'react-bootstrap';
import bootstrap from'../img/skills/bootstrap.png';
import react from'../img/skills/react.png';
import flutter from'../img/skills/flutter.png';
import css from'../img/skills/css.png';
import html from'../img/skills/html.png';
import javascript from'../img/skills/javascript.png';
import java from'../img/skills/java.png';
import kotlin from'../img/skills/kotlin.png';
import laravel from'../img/skills/laravel.png';
import php from'../img/skills/php.png';
import winforms from'../img/skills/winforms.png';
import wpf from'../img/skills/wpf.png';

export default function Skills() {
  const colData = [
    { src: laravel, alt: 'Softagics Client' },
    { src: php, alt: 'Softagics Client' },
    { src: react, alt: 'Softagics Client' },
    { src: javascript, alt: 'Softagics Client' },
    { src: flutter, alt: 'Softagics Client' },
    { src: java, alt: 'Softagics Client' },
    { src: kotlin, alt: 'Softagics Client' },
    { src: winforms, alt: 'Softagics Client' },
    { src: wpf, alt: 'Softagics Client' },
    { src: html, alt: 'Softagics Client' },
    { src: css, alt: 'Softagics Client' },
    { src: bootstrap, alt: 'Softagics Client' }
  ];

  return (
    <>
    <section data-aos="fade-up" id="clients" className='clients section-bg'>
      <Container data-aos="zoom-in">
        <Row className='justify-content-center'>
        {colData.map((data, index) => (
        <Col key={index} xs={12} md={3} lg={2}>
          <Image src={data.src} alt={data.alt} />
        </Col>
      ))}
        </Row>
      </Container>
    </section>
    </>
    
  )
}
