import React from 'react'
import {Col, Container, Row } from "react-bootstrap";
import Header from "./InnerHeader";
import headerbg from "../img/contact-bg.jpeg";
export default function Privacy_Policy_Jems() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Privacy Policy JEMS" />

      <section className='jems-privacy' data-aos="fade-up">
        <Container>
          <h1>Privacy Policy</h1>
          <p>Last updated December 20, 2023</p>
          <p>This privacy notice for Jadeed EMS shortly known as ‘JEMS‘ Android Application , describes how and why we might collect, store, use, and/or share (“process”) your information when you use our services (“Services”), such as when you:</p>
          <p><i class="fa-solid fa-arrow-right"></i> Download and use our mobile application (JEMS), that links to this privacy notice, Engage with us in other related ways. Download and use our mobile application (JEMS), that links to this privacy notice, Engage with us in other related ways.</p>
          <h5>Questions or concerns?</h5>
          <p>Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact in related ways.</p>
          <p>Summary of Key Points</p>
          <p>This summary provides key points from our privacy notice, but you can find out more details following each key point or by using our table of contents below to find the section you are looking for.</p>
          <h6>Do we process any sensitive personal information?</h6>
          <p>We do not process sensitive personal information.</p>
          <h6>Do we receive any information from third parties?</h6>
          <p>No we do not receive information from public databases, marketing partners, social media platforms, and other outside sources.</p>
          <h6>How do we process your information?</h6>
          <p>We process your information to provide, improve, and administer our Services, communicate with you and to comply with law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so.</p>
          <h6>In what situations and with which parties do we share personal information?</h6>
          <p>We do not share information in specific situations and nor with specific third parties.</p>
          <h6>How do we keep your information safe?</h6>
          <p>We have organizational and technical processes and procedures in Place to protect your personal information. However, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.</p>
          <h6>What are your rights?</h6>
          <p>Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information.</p>
          <h6>How do you exercise your rights?</h6>
          <p>The easiest way to exercise your rights is by submitting a data subject access request, or by contacting us. We will consider and act upon any request in accordance with applicable data protection laws.</p>
          <p>Want to learn more about what we do with any information we collect? Review the privacy notice in full.</p>
          <h5>Table of consents</h5>
          <ol type="1">
            <li>what information do we collect?</li>
            <li>how do we process your information?</li>
            <li>when and with whom do we share your personal information?</li>
            <li>how long do we keep your information?</li>
            <li>how do we keep your information safe?</li>
            <li>what are your privacy rights?</li>
            <li>controls for do-not-track features</li>
            <li>do we make updates to this notice?</li>
            <li>how can you contact us about this notice?</li>
            <li>how can you review, update, or delete the data we collect from you?</li>
          </ol>
          <h5>1. what information do we collect?</h5>
          <p>in short: we collect persona/ information that you provide to us. we collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and services, when you participate in activities on the services, or otherwise when you contact us.</p>
          <h6>sensitive information</h6>
          <p>we do not process sensitive information.</p>
          <h6>application data</h6>
          <p>if you use our application(s), we also may collect the following information if you choose to provide us with access or permission:</p>
          <ul>
            <li>we may request to send you push notifications regarding your account or certain features of the application(s). if you wish to opt out from receiving these types of communications, you may turn them off in your device’s settings.</li>
          </ul>
          <p>this information is primarily needed to maintain the security and operation to our application(s), for troubleshooting, and tor our internal analytics and reporting purposes.</p>
          <h6>information collected from other sources</h6>
          <p>in short: we do not collect data from public databases, marketing partners, and other outside sources.</p>
          <h5>2. how do we process your information?</h5>
          <ul>
            <li>in short: we process your information to provide, improve, and administer our services, communicate with you, and to comply with law. we may also process your information for other purposes with your consent.</li>
          </ul>
          <p>we process your personal information for a variety of reasons, depending on how you interact with our services, including:</p>
          <ul>
            <li>to respond to user inquiries/offer support to users. we may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.</li>
          </ul>
          <h5>3. when and with whom do we share your personal information?</h5>
          <p>in short: we do not share information described in this section and/or with the following third patties.</p>
          <h5>4. how long do we keep your information?</h5>
          <p>in short: we keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law we will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (other legal requirements).</p>
          <p>when we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>
          <h5>5. how do we keep your information safe?</h5>
          <p>in short: we aim to protect your personal information through a system of organizational and technical security measures.</p>
          <p>we have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. however, despite our safeguards and efforts to secure your information, no electronic transmission over the internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. although we will do our best to protect your personal information, transmission of personal information to and from our services is at your own risk. you should only access the services within a secure environment.</p>
          <h5>6. what are your privacy rights?</h5>
          <p>in short: you may review, change, or terminate your account at any time.</p>
          <p>withdrawing your consent: if we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. you can withdraw your consent at any time by contacting us by using the contact details provided in the section “how can you contact us about this notice?” below.</p>
          <p>however, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.</p>
          <p>if you have questions or comments about your privacy rights, you may email us in related ways.</p>
          <h5>7. controls for do-not-track features</h5>
          <p>most web browsers and some mobile operating systems and mobile applications include a do-not-track (“dnt’) feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. at this stage no uniform technology standard for recognizing and implementing dnt signals has been finalized. as such, we do not currently respond to dnt browser signals or any other mechanism that automatically communicates your choice not to be tracked online. if a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.</p>
          <h5>8. do we make updates to this notice?</h5>
          <p>in short: yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
          <p>we may update this privacy notice from time to time. the updated version will be indicated by an updated “revised” date and the updated version will be effective as soon as it is accessible. if we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. we encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</p>
          <h5>9.  how can you contact us about this notice?</h5>
          <p>if you have questions or comments about this notice, you may email us at info@softagics.com or contact us by post at:</p>
          <p>write us on: softagics house 327 f block shah rukn e alam cly ,multan, punjab 60000 pakistan</p>
          <h5>10. how can you review, update, or delete the data we collect from you?</h5>
          <p>you have the right to request access to the personal information we process from you, change that information, or delete it. to request to review, update, or delete your personal information, please fill out and submit a data subject access request.</p>
        </Container>
      </section>
    </>
  )
}
