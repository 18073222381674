import React from "react";
import { Row, Col, Container,} from "react-bootstrap";
import Header from "./InnerHeader";
import headerbg from "../img/contact-bg.jpeg";
import ServicesList from './ServicesList';

const YourComponent = () => {
  return (
    <>
      <Header backgroundImage={headerbg} title="Services" />
      <section data-aos="fade-up">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <div className="section-title text-center">
                <h1>The Perfect Services We’re Offering</h1>
                <p>
                  We offers a full-cycle software development services that meet
                  varied business requirements from IT strategy consulting to
                  the end-to-end development of scalable solutions.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
        <ServicesList/>
      </section>
    </>
  );
};

export default YourComponent;
