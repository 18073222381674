import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
export default function LookingForBest() {
  return (
    <section className="lookingforbest" data-aos="fade-up">
      <Container>
        <Row className="justify-content-md-center align-item-center">
          <Col xs={12} md={9}>
            <h1>Let’s Get Your Project Started</h1>
          </Col>
          <Col xs={12} md={3} className="d-flex align-items-center justify-content-end">
            <Link to="/contact">
              <Button variant="light" size="lg">Contact With Us</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
