import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/contact-bg.jpeg";
import web from "../../img/services/webdevelopment.svg";

export default function Web() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Web Development" />
      <section>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <h2>
                Transform your online presence. Powerful web development
                solutions
              </h2>
              <p>
                <b>
                  In today's digital age, a well-crafted website is essential
                  for any business. It serves as your online storefront, a
                  platform to connect with customers, and a powerful tool to
                  showcase your brand and offerings. That's where Softagics
                  comes in.
                </b>
              </p>
              <p>
                Softagics is a leading provider of web development services,
                specializing in creating custom solutions that cater to your
                unique business needs. Their team of skilled developers
                possesses a deep understanding of the latest technologies and
                trends, ensuring they can deliver high-performing websites that
                are both visually stunning and user-friendly.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <Image src={web} fluid alt="" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h5>Here's what sets Softagics apart:</h5>
              <ul>
                <li>
                  <b>Custom Web Development:</b> They don't believe in a
                  one-size-fits-all approach. They work closely with you to
                  understand your specific goals and target audience, then craft
                  a website that perfectly aligns with your vision.
                </li>
                <li>
                  <b>Focus on Scalability and Growth:</b> They build websites
                  that are designed to scale alongside your business. Their
                  solutions are adaptable and can accommodate future growth,
                  ensuring your website remains effective as your business
                  thrives.
                </li>
                <li>
                  <b>Commitment to Quality:</b> They take pride in delivering
                  exceptional quality. Their meticulous development process and
                  rigorous testing procedures guarantee a website that is free
                  of errors and delivers an optimal user experience.
                </li>
                <li>
                  <b>Data-Driven Approach:</b> They leverage data and analytics
                  to gain valuable insights into user behavior and website
                  performance. This data-driven approach allows them to
                  continually optimize your website for better conversions and
                  improved results.
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h5>
                Softagics offers a wide range of web development services,
                including:
              </h5>
              <ul>
                <li>Content Management System (CMS) Development</li>
                <li>E-commerce Development</li>
                <li>Custom Web Application Development</li>
                <li>User Interface (UI) and User Experience (UX) Design</li>
                <li>Responsive Web Design</li>
                <li>Website Maintenance and Support</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
