  import React from "react";
  import { Card, Container, Row, Col } from "react-bootstrap";
  
  export default function Solutions() {
    const data = [
      {
        icon:"fa-solid fa-sack-dollar",
        title: "POS",
        alt: "Point Of Sale",
      },
      {
        icon:"fa-solid fa-sack-dollar",
        title: "Banking",
        alt: "Banking",
      },
      {
        icon: "fa-solid fa-briefcase",
        title: "Capital markets",
        alt: "Capital markets",
      },
      {
        icon: "fa-solid fa-school",
        title: "Healthcare",
        alt: "Healthcare",
      },
      {
        icon: "fa-solid fa-hotel",
        title: "Enterprise",
        alt: "Hostel Management",
      },
      {
        icon: "fa-solid fa-comment-dots",
        title: "Education",
        alt: "Education",
      },
      {
        icon: "fa-solid fa-person-arrow-up-from-line",
        title: "ERP",
        alt: "ERP",
      },
      {
        icon: "fa-solid fa-tree-city",
        title: "Hostel",
        alt: "Hostel",
      },
      {
        icon: "fa-solid fa-tree-city",
        title: "Park Management",
        alt: "Park Management System",
      },
      {
        icon: "fa-solid fa-tree-city",
        title: "Utilities",
        alt: "Utilities",
      },
    ];
  
    return (
      <>
      <section data-aos="fade-up">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <div className="section-title text-center">
                <h2>Solutions</h2>
                <h1>Empowering progress, one solution at a time</h1>
                <p>Our deep experience in these and many other industries helps us rapidly deliver specialized solutions.</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {data.map((item, index) => (
              <Col
                key={index}
                xs={12}
                md={4}
                className="solution-item product-item mb-3 d-flex align-items-stretch"
              >
                <Card className="hvr-rectangle-out">
                  <Card.Body>
                    <Row className="align-items-center">
                      <Col xs={12} md={3} className="icon"><i className={item.icon}></i></Col>
                      <Col xs={12} md={9}><h4>{item.title}</h4></Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        </section>
      </>
    );
  }
  