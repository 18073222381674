import React from "react";
import { Container, Row, Col, Image, CardBody } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/contact-bg.jpeg";
import desktop from "../../img/services/desktopdevelopment.svg";

export default function Desktop() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Desktop APP Development" />
      <section>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <h2>
                Bring your vision to life with custom desktop applications from
                Softagics
              </h2>
              <p>
                <b>
                  In today's mobile-first world, desktop applications may seem
                  like a relic of the past. But for many businesses, desktop
                  apps remain an essential tool for streamlining workflows,
                  improving productivity, and providing a robust user
                  experience. That's where Softagics comes in.
                </b>
              </p>
              <p>
                Softagics is a leading provider of desktop application
                development services. Our team of experienced developers has a
                proven track record of designing, developing, and deploying
                powerful and user-friendly desktop applications that meet your
                specific needs.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <Image src={desktop} fluid alt="" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h5>
                Here are just a few of the benefits of choosing Softagics for
                your desktop app development project:
              </h5>
              <ul>
                <li>
                  <b>Deep expertise:</b> Our developers have a comprehensive
                  understanding of the latest desktop development technologies
                  and frameworks.
                </li>
                <li>
                  <b>Custom-tailored solutions:</b> We don't believe in
                  one-size-fits-all solutions. We take the time to understand
                  your unique business needs and develop an application that
                  perfectly aligns with them.
                </li>
                <li>
                  <b>Focus on user experience:</b> We believe that a great
                  desktop application should be intuitive and easy to use. We
                  prioritize user experience in every step of the development
                  process.
                </li>
                <li>
                  <b>Seamless integration:</b> We can integrate your desktop
                  application with existing systems and databases to ensure a
                  smooth and efficient workflow.
                </li>
                <li>
                  <b>Ongoing support:</b> We offer comprehensive ongoing support
                  to ensure that your desktop application continues to meet your
                  needs over time.
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h5>
              Whether you need a simple productivity tool or a complex enterprise application, Softagics can help you bring your vision to life.
              </h5>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
