import React, { useEffect, useRef } from "react";
import { Col, Row, Container } from "react-bootstrap";

const Counter = () => {
  const animateCount = (element, options) => {
    const {
      start = 0,
      end = 100,
      easing = "linear",
      duration = 400,
      complete = () => {},
    } = options;
    const range = end - start;
    let current = start;
    const increment = range > 0 ? 1 : -1;
    const stepTime = Math.abs(Math.floor(duration / range));

    const timer = setInterval(() => {
      current += increment;
      element.textContent = current;

      if (
        (increment > 0 && current >= end) ||
        (increment < 0 && current <= end)
      ) {
        clearInterval(timer);
        complete();
      }
    }, stepTime);

    return timer;
  };

  const counters = [
    {
      number: 50,
      label: "Project Completed",
      icon: "fa-solid fa-medal",
      ref: useRef(null),
    },
    {
      number: 100,
      label: "Happy clients",
      icon: "fa-solid fa-handshake",
      ref: useRef(null),
    },
    {
      number: 26,
      label: "Active Client",
      icon: "fa-solid fa-globe",
      ref: useRef(null),
    },
    {
      number: 46,
      label: "Team Members",
      icon: "fa-solid fa-people-group",
      ref: useRef(null),
    },
  ];

  useEffect(() => {
    const timers = counters.map(({ number, ref }) =>
      animateCount(ref.current, { end: number, duration: 3000 })
    );

    return () => {
      timers.forEach((timer) => clearInterval(timer));
    };
  }, []);

  return (
    <section className="counter" data-aos="fade-up">
      <Container>
        <Row className="justify-content-center">
          {counters.map(({ number, label, icon, ref }, index) => (
            <Col  data-aos="zoom-in" data-aos-delay="100"
              xs={12}
              sm={6}
              md={3}
              key={index}
              className="item wow fadeInUpBig animated"
              data-number={number}
              style={{ visibility: "visible" }}
            >
              <i className={icon}></i>
              <h3 ref={ref} className="number">
                {number}
              </h3>
              <p>{label}</p>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default Counter;
