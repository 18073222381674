import React from "react";
import {
  Row,
  Col,
  Image,
  Button,
  Container,
  Card,
  CardBody,
} from "react-bootstrap";
import { Link } from "react-router-dom"; // Assuming you are using React Router
import web from "../img/services/webdevelopment.svg";
import mobile from "../img/services/mobileAPPdevelopment​.svg";
import uiux from "../img/services/uiuxdesign.svg";
import graphicdesign from "../img/services/graphicdesign.svg";
import software from "../img/services/softwaredevelopment.svg";
import ecommerce from "../img/services/ecommercesolution.svg";
import internet from "../img/services/internetmarketing.svg";
import custom from "../img/services/customAPPevelopment​.svg";

const ContentArray = [
  {
    image: software,
    alt: "Software Development",
    title: "Software Development",
    description:
      "We specialize in custom web, mobile, and desktop apps to meet your needs. From user-friendly web apps to powerful mobile solutions and robust desktop software, we've got you covered.",
    buttonText: "Read More",
    link: "/Software",
    delay: "100",
  },
  {
    image: web,
    alt: "Web Development",
    title: "Web Development",
    description:
      "Softagics is a leading provider of web development services, specializing in creating custom solutions that cater to your unique business needs. Their team of skilled developers possesses a deep understanding of the latest technologies and trends, ensuring they can deliver high-performing websites that are both visually stunning and user-friendly.",
    buttonText: "Read More",
    link: "/Web",
    delay: "200",
  },
  {
    image: mobile,
    alt: "Mobile App Development",
    title: "Mobile App Development",
    description:
      "Softagics excels in mobile app development, offering tailored solutions with cutting-edge technology. Our seasoned team ensures a seamless process from concept to deployment, driven by creativity and precision. With a proven track record and a commitment to innovation, we're the trusted partner for businesses aiming to leverage mobile tech for growth and innovation.",
    buttonText: "Read More",
    link: "/Mobile",
    delay: "100",
  },
  {
    image: custom,
    alt: "Custom Development",
    title: "Custom Development",
    description:
      "We craft bespoke software solutions tailored to your specific requirements and workflows. Unlike off-the-shelf software, our custom approach ensures seamless integration with your existing systems, addresses your unique challenges, and empowers your team with the functionalities they need to thrive. innovation.",
    buttonText: "Read More",
    link: "/Custom",
    delay: "200",
  },
  {
    image: uiux,
    alt: "UI/UX Design​",
    title: "UI/UX Design​​",
    description:
      "Our UI/UX design team is a powerhouse of creativity and expertise. They don't just design interfaces that look good, they craft user experiences that are intuitive, enjoyable, and user behavior and a keen eye for visual appeal, they'll ensure your e-commerce solution is a pleasure to navigate and keeps customers coming back for more.",
    buttonText: "Read More",
    link: "/UIUX",
    delay: "300",
  },
  {
    image: graphicdesign,
    alt: "Graphic Design​",
    title: "Graphic Design​​",
    description:
      "From eye-catching logos and branding materials to captivating social media graphics and website visuals, our graphic designers help you establish a strong visual identity that sets you apart.",
    buttonText: "Read More",
    link: "/Graphic",
    delay: "100",
  },
  {
    image: ecommerce,
    alt: "E-Commerce Solution",
    title: "E-Commerce Solution",
    description:
      "Softagics is a leading provider of e-commerce solutions, empowering businesses of all sizes to establish and grow their online presence. We offer a comprehensive suite of services designed to streamline your e-commerce operations and maximize  sales potential.",
    buttonText: "Read More",
    link: "/Commerce",
    delay: "200",
  },
  {
    image: internet,
    alt: "Internet Marketing​",
    title: "Internet Marketing​​",
    description:
      "In today's digital world, a strong online presence is crucial. Our internet marketing specialists leverage proven strategies to increase your website traffic, brand awareness, and ultimately, your sales.",
    buttonText: "Read More",
    link: "/Internet",
    delay: "300",
  },
  // Add more objects for additional content
];

const YourComponent = () => {
  return (
    <>
      <Container data-aos="fade-up">
        <Row className="justify-content-center align-items-center services-card">
          {ContentArray.map((content, index) => (
            <Col
              lg={4}
              md={4}
              xs={12}
              key={index}
              className="mb-4 hvr-float-shadow"  data-aos="zoom-in" data-aos-delay={content.delay}
            >
              <Card>
                <Card.Img variant="top" src={content.image} alt={content.alt} />
                <CardBody>
                  <Card.Title>{content.title}</Card.Title>
                  <Card.Text>{content.description}</Card.Text>
                  <Link to={content.link}>
                    <Button variant="primary">
                      {content.buttonText}{" "}
                      <i className="fa-solid fa-arrow-right"></i>
                      {/* <i className={card.buttonIcon}></i> */}
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
};

export default YourComponent;
