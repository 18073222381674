import React from "react";
import { Row, Col } from "react-bootstrap";

const SectionTitle = ({ caption, title, desc }) => {
  return (
    <Row className="justify-content-center">
      <Col xs={12} md={8}>
        <div className="section-title text-center">
          <h2>{caption}</h2>
          <h1>{title}</h1>
          <p>{desc}</p>
        </div>
      </Col>
    </Row>
  );
}

export default SectionTitle;
