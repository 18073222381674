import React from "react";
import { Container, Row, Col, Image, CardBody } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/contact-bg.jpeg";
import commerce from "../../img/services/ecommercesolution.svg";

export default function Commerce() {
  return (
    <>
      <Header backgroundImage={headerbg} title="E-Commerce Solutions" />
      <section>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <h2>Sell Smarter, Not Harder: Powerful E-Commerce Solutions</h2>
              <p>Softagics is a leading provider of e-commerce solutions, empowering businesses of all sizes to establish and grow their online presence. We offer a comprehensive suite of services designed to streamline your e-commerce operations and maximize your sales potential.</p>
            </Col>
            <Col xs={12} md={6}>
              <Image src={commerce} fluid alt="" />
            </Col>
            <Col xs={12} md={12} className="mb-3">
              <h5>Here's what Softagics can do for your business:</h5>
              <ul>
<li><b>E-commerce platform development:</b> We design and develop user-friendly e-commerce platforms that are tailored to your specific needs and industry. Our solutions are scalable and secure, ensuring a seamless online shopping experience for your customers.</li>
<li><b>Integration services:</b> We seamlessly integrate your e-commerce platform with your existing systems, such as inventory management, accounting, and CRM software. This ensures smooth data flow and eliminates manual data entry tasks.</li>
<li><b>Marketing and SEO optimization:</b> We help you attract more visitors to your online store through effective marketing strategies and search engine optimization (SEO) techniques. This increases your visibility and drives organic traffic to your website.</li>
<li><b>Payment gateway integration:</b> We integrate secure payment gateways into your e-commerce platform, allowing customers to make safe and convenient online transactions.</li>
<li><b>Ongoing maintenance and support:</b> We provide ongoing maintenance and support to ensure your e-commerce platform is always up-to-date, secure, and functioning optimally.</li>
              </ul>
            </Col>
            <Col xs={12} md={12}>
              <h5>Benefits of choosing Softagics as your e-commerce solution provider:</h5>
              <ul>
<li><b>Increased sales and revenue:</b> Our e-commerce solutions help you reach a wider audience, convert more visitors into customers, and ultimately boost your sales and revenue.</li>
<li><b>Improved customer experience:</b> We provide user-friendly e-commerce platforms that offer a smooth and enjoyable shopping experience for your customers.</li>
<li><b>Reduced costs:</b> Our e-commerce solutions can help you streamline your operations, reduce manual tasks, and save on costs.</li>
<li><b>Scalability and growth:</b> Our solutions are designed to scale with your business, so you can be confident that your e-commerce platform can accommodate your future growth.</li>
              </ul>
            </Col>
            <Col xs={12} md={12}>
              <h1>Ready to take your business online?</h1>
              <p>Contact Softagics today to learn more about our e-commerce solutions and how we can help you achieve your online success goals.</p>
            </Col>
          </Row>
          
        </Container>
      </section>
    </>
  )
}
