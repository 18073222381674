import React from "react";
import { Container, Row, Col, Image, CardBody } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/contact-bg.jpeg";
import graphic from "../../img/services/graphicdesign.svg";

export default function Graphic() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Graphic Designing" />
      <section>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <h2>Elevate Your Brand with Stunning Graphic Design</h2>
              <p>In today's visual world, impactful graphic design is essential for any business.
  Whether you're a startup building your brand identity or an established company seeking a refresh, we can help.</p>
            </Col>
            <Col xs={12} md={6}>
              <Image src={graphic} fluid alt="" />
            </Col>
            <Col xs={12} md={12}>
              <h5>Our team of skilled graphic designers creates:</h5>
              <ul>
                <li>Memorable Logos & Brand Identity</li>
<li>Eye-Catching Marketing Materials (Brochures, Flyers, Posters)</li>
<li>Social Media Graphics & Ads</li>
<li>Professional Presentations</li>
<li>Print & Digital Design Solutions</li>
              </ul>
            </Col>
          </Row>
          
        </Container>
      </section>
    </>
  )
}
