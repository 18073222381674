import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Image, Col, Container, Row, Card, CardBody } from "react-bootstrap";
import client1 from "../img/clients/punjabpolice.png";
import client2 from "../img/clients/traficpolice.png";
import client3 from "../img/clients/citycollege.png";
import client4 from "../img/clients/shangrilla.png";
import client5 from "../img/clients/finead.png";
import client6 from "../img/clients/jadeedbazar.png";
import client7 from "../img/clients/jadeedems.png";
import client8 from "../img/clients/jadeedmunshi.png";

const ClientSlider = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 5000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const data = [
    { client: client1, alt: "Pakistan Punjab Police" },
    { client: client2, alt: "Trafic Police Punjab" },
    { client: client3, alt: "City College" },
    { client: client4, alt: "Shangrilla " },
    { client: client5, alt: "FineAd" },
    { client: client6, alt: "Jadeed Bazar" },
    { client: client7, alt: "Jadeed Education Management System" },
    { client: client8, alt: "Jadeed Munshi" },
  ];

  return (
    <section className="clients-slider section-bg" data-aos="fade-up">
      <Container>
        <Row>
          <Slider {...settings}>
            {data.map((logo, index) => (
              <Col
                key={index}
                xs={12}
                md={4}
                lg={3}
                className="mb-3 hvr-float-shadow"
              >
                <Image src={logo.client} alt={logo.alt} />
              </Col>
            ))}
          </Slider>
        </Row>
      </Container>
      </section>
  );
};

export default ClientSlider;
