import React from "react";
import { Container, Row, Col, Image, CardBody } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/contact-bg.jpeg";
import internet from "../../img/services/internetmarketing.svg";

export default function Internet() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Internet Marketing" />
      <section>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <h2>
                Get Found Online: Powerful Internet Marketing Services for Your
                Business
              </h2>
              <p>
                Don't just blend into the digital crowd. Our expert internet
                marketing team will be your champions, crafting a strategic plan
                to showcase your brand and products across the web. They'll
                leverage their knowledge of SEO, social media marketing, and
                targeted advertising to drive traffic to your online store and
                convert browsers into loyal customers.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <Image src={internet} fluid alt="" />
            </Col>
            <Col xs={12} md={12} className="mb-3">
              <h5>Content for Your Services:</h5>
              <ul>
                <li>
                  <b>Search Engine Optimization (SEO):</b> Increase your
                  website's visibility in search engine results pages (SERPs)
                  for relevant keywords, driving organic traffic to your site.
                </li>
                <li>
                  <b>Pay-Per-Click (PPC) Advertising:</b> Reach your target
                  audience on search engines and social media platforms with
                  targeted ad campaigns.
                </li>
                <li>
                  <b>Social Media Marketing:</b> Build brand awareness, engage
                  with your audience, and drive traffic to your website through
                  social media channels.
                </li>
                <li>
                  <b>Content Marketing:</b> Create high-quality content that
                  attracts, educates, and converts your target audience.
                </li>
                <li>
                  <b>Email Marketing:</b> Build relationships with your
                  audience, nurture leads, and drive sales through targeted
                  email campaigns.
                </li>
                <li>
                  <b>Website Design & Development:</b> Create a user-friendly
                  and high-converting website that reflects your brand and
                  drives results.
                </li>
              </ul>
            </Col>
            <Col xs={12} md={12}>
              <h5>Benefits & Results:</h5>
              <ul>
                <li>
                  <b>Increased sales and revenue:</b>Increased Website Traffic:
                  Attract more qualified visitors to your website.
                </li>
                <li>
                  <b>Improved Lead Generation:</b> Generate more leads and grow
                  your sales pipeline.
                </li>
                <li>
                  <b>Higher Conversion Rates:</b> Convert more website visitors
                  into customers.
                </li>
                <li>
                  <b>Measurable Results:</b> Track your progress and see the
                  impact of your campaigns.
                </li>
                <li>
                  <b>Improved Brand Awareness:</b> Build brand awareness and
                  establish yourself as a thought leader in your industry.
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
