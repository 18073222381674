import React from "react";
import { Container, Row, Col, Image, CardBody } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/contact-bg.jpeg";
import uiux from "../../img/services/uiuxdesign.svg";

export default function Uiux() {
  return (
    <>
      <Header backgroundImage={headerbg} title="UIUX Design" />
      <section>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <h2>
              Crafting Exceptional User Experiences Through UI/UX Design Solutions
              </h2>
              <p>
                <b>
                In today's digital landscape, users expect intuitive and visually appealing interfaces. That's where Softagics comes in, a trusted provider of UI/UX design solutions. Their team of skilled designers creates user-centric experiences that not only look great but also function flawlessly
                </b>
              </p>
              <p>Our UI/UX design team is a powerhouse of creativity and expertise. They don't just design interfaces that look good, they craft user experiences that are intuitive, enjoyable, and meet your target audience's needs. With a deep understanding of user behavior and a keen eye for visual appeal, they'll ensure your e-commerce solution is a pleasure to navigate and keeps customers coming back for more.</p>
            </Col>
            <Col xs={12} md={6}>
              <Image src={uiux} fluid alt="UIUX Design" />
            </Col>
          </Row>
          <Row>
          <Col xs={12} md={12} className="mb-3">
          <h5>What is UI/UX Design?</h5>
          <p>UI (User Interface) design focuses on the visual elements of a product, such as buttons, menus, and layouts. UX (User Experience) design considers the entire user journey, ensuring interactions are smooth and enjoyable. Softagics addresses both aspects seamlessly.</p>
          </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h5>Why Choose Softagics for UI/UX Design Solutions?</h5>
              <ul>
                <li>
                  <b>User-Centered Approach: </b> Softagics prioritizes understanding your target audience to design interfaces that cater to their needs and expectations.
                </li>
                <li>
                  <b>Experience and Expertise::</b> Their team possesses a wealth of experience in crafting exceptional UI/UX designs across various industries.
                </li>
                <li>
                  <b>Enhanced Usability:</b> They focus on creating interfaces that are not only aesthetically pleasing but also easy to navigate and use.
                </li>
                <li>
                  <b>Increased User Engagement:</b> A well-designed UI/UX experience keeps users engaged and coming back for more.
                </li>
                <li>
                  <b>Improved Brand Image:</b> A polished and user-friendly interface reflects positively on your brand and builds trust with your customers.
                </li>
              </ul>
              <h1>Softagics doesn't just design interfaces; they design experiences.</h1>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
