import React, { useState } from "react";
import {Button, Form } from "react-bootstrap";
import Header from "./InnerHeader";
import headerbg from '../img/contact-bg.jpeg'
import GoogleMaps from "./GoogleMap";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Container,
} from "react-bootstrap";
import ContactForm from "./ContactForm";

const cardData = [
  {
    title: "Location",
    content: "12-1, block A2 Opp. Cine Star ,Township, Lahore",
    icon: "fa-solid fa-location-dot",
    delay: "100",
  },
  {
    title: "Call",
    content: "+92-32-60667666",
    icon: "fa-solid fa-phone",
    delay: "200",
  },
  {
    title: "Email",
    content: "info@softagics.com",
    icon: "fa-solid fa-envelope",
    delay: "300",
  },
];

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can handle form submission, e.g., send data to a backend server
    console.log(formData);
  };

  return (
    <>
      <Header backgroundImage={headerbg} title="Clients"/>
      <section className="contact" data-aos="fade-up">
        <Container>
          <Row className="justify-content-center mb-5">
            {cardData.map((card, index) => (
              <Col key={index} xs={6} md={4} className="card-container"  data-aos="zoom-in" data-aos-delay={card.delay}>
                <Card>
                  <CardBody>
                  <div className="icon">
                    <i className={`fa-solid ${card.icon}`}></i>
                  </div>
                    <CardTitle>{card.title}</CardTitle>
                    <CardText>{card.content}</CardText>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          <Row>
            <Col xs={6} md={6} className="card-map">
            <GoogleMaps/>
            </Col>
            <Col xs={6} md={6} className="card-form">
              <Card>
                <Card.Body>
                  <ContactForm/>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
