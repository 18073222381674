import React from "react";
import { Container, Row, Col} from "react-bootstrap";

export default function NotFoundPage() {
  return (
    <>
    <section className="bg-white" data-aos="fade-up">
      <Container>
        <Row className="justify-content-center text-center">
          <Col>
            <h1>404 - Page Not Found</h1>
            <p>The page you are looking for does not exist.</p>
          </Col>
        </Row>
      </Container>
      </section>
    </>
  );
}
