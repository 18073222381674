import React from "react";
import { Image, Col, Container, Row, Card, CardBody } from "react-bootstrap";
import Header from "./InnerHeader";
import headerbg from "../img/contact-bg.jpeg";

import client1 from "../img/clients/punjabpolice.png";
import client2 from "../img/clients/traficpolice.png";
import client3 from "../img/clients/citycollege.png";
import client4 from "../img/clients/shangrilla.png";
import client5 from "../img/clients/finead.png";
import client6 from "../img/clients/jadeedbazar.png";
import client7 from "../img/clients/jadeedems.png";
import client8 from "../img/clients/jadeedmunshi.png";

const data = [
  { client: client1, alt: "Pakistan Punjab Police", delay: "100", },
  { client: client2, alt: "Trafic Police Punjab", delay: "200", },
  { client: client3, alt: "City College", delay: "300", },
  { client: client4, alt: "Shangrilla " , delay: "500",},
  { client: client5, alt: "FineAd", delay: "600", },
  { client: client6, alt: "Jadeed Bazar", delay: "700", },
  { client: client7, alt: "Jadeed Education Management System", delay: "800", },
  { client: client8, alt: "Jadeed Munshi", delay: "900", },
];

export default function Clients() {
  return (
    <>
    <Header backgroundImage={headerbg} title="Clients" />
      <section data-aos="fade-up">
        <Container>
          <Row className="justify-content-center align-items-center">
            {data.map((item, index) => (
              <Col key={index} xs={12} md={4} lg={3} className="mb-3 hvr-float-shadow"  data-aos="zoom-in" data-aos-delay={item.delay}>
                <Card>
                  <CardBody>
                    <Image src={item.client} alt={item.alt} fluid/>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
