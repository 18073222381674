import React from "react";
import HomeCarousel from "./HomeCarousel";
import WayofWork from "./WayofWork";
import About from "./About";
import Services from "./ServicesHome";
import Projects from "./Projects";
import ProductsHome from "./ProductsHome";
import ClientSlider from './ClientSlider';
import Counter from "./Counter";

export default function Home() {
  return (
    <>
      <HomeCarousel/>
      <WayofWork/>
      <About/>
      <Services/>
      <Projects/>
      <ProductsHome/>
      <ClientSlider />
      <Counter/>
    </>
  );
}
