import React from 'react';

class BackToTopButton extends React.Component {
  handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // Smooth scroll behavior
    });
  };

  render() {
    return (
      <button className="back-to-top-button" onClick={this.handleClick}>
        <i class="fa-solid fa-arrow-up"></i>
      </button>
    );
  }
}

export default BackToTopButton;
