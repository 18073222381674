import React from "react";
import {Button, Col, Container, Row } from "react-bootstrap";
import Header from "./InnerHeader";
import headerbg from "../img/contact-bg.jpeg";
import SectionTitle from "./SectionTitle";
export default function Careers() {
  const jobListings = [
    { title: "Job Title 1", description: "Description 1" },
    { title: "Job Title 2", description: "Description 2" },
    { title: "Job Title 3", description: "Description 3" },
  ];

  return (
    <>
      <Header backgroundImage={headerbg} title="Work With Us" />

      <section className="careers" data-aos="fade-up">
        <Container>
          <SectionTitle
            caption="Current"
            title="Open Positions"
            desc="Our company, headquartered in Pakistan, eagerly welcomes new talent to join our dynamic team."
          />
          {jobListings.map((job, index) => (
            <div key={index} className="job-listing">
            <Row className="align-items-center">
              <Col xs={12} md={10} className="job-details">
                <h6>{job.title}</h6>
                <p>{job.description}</p>
              </Col>
              <Col xs={12} md={2} className="d-flex justify-content-end">
                <Button variant="primary">Apply Now <i class="fa-solid fa-arrow-right"></i></Button>
              </Col>
            </Row>
            </div>
          ))}
        </Container>
      </section>
    </>
  );
}
