import React, { useState } from "react";
import {Card, Button, Container, Row, Col, Image, CardBody } from "react-bootstrap";
import Header from "./InnerHeader";
import headerbg from "../img/contact-bg.jpeg";
export default function Products() {
  const data = [
    {
      icon: "fa-solid fa-sack-dollar",
      title: "Financial System",
      alt: "Financial System",
      delay: "100",
    },
    {
      icon: "fa-solid fa-briefcase",
      title: "Business to Business",
      alt: "Business to Business",
      delay: "200",
    },
    {
      icon: "fa-solid fa-school",
      title: "Education Management",
      alt: "Education Management",
      delay: "300",
    },
    {
      icon: "fa-solid fa-hotel",
      title: "Hostel Management",
      alt: "Hostel Management",
      delay: "400",
    },
    {
      icon: "fa-solid fa-comment-dots",
      title: "Feedback System",
      alt: "Feedback System",
      delay: "500",
    },
    {
      icon: "fa-solid fa-person-arrow-up-from-line",
      title: "Queue Management",
      alt: "Feedback System",
      delay: "600",
    },
    {
      icon: "fa-solid fa-tree-city",
      title: "Park Management",
      alt: "Park Management System",
      delay: "700",
    },
    {
      icon: "fa-solid fa-screwdriver-wrench",
      title: "Workshop Management",
      alt: "Workshop Management System",
      delay: "900",
    },
    {
      icon: "fa-solid fa-chart-line",
      title: "Sales Management",
      alt: "Sales Management System",
      delay: "900",
    },
  ];


  return (
    <>
    <Header backgroundImage={headerbg} title="Products" />
      <section data-aos="fade-up">
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={6}>
              <div className="section-title text-center">
                <h2>Products</h2>
                <h1>Crafted Comfort</h1>
                <p>Innovative Solutions for Everyday Living</p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {data.map((item, index) => (
              <Col
                key={index}
                xs={12}
                md={4}
                className="product-item mb-3 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={item.delay}
              >
                <Card className="hvr-rectangle-out">
                  <Card.Body>
                    <div className="icon">
                      <i className={item.icon} />
                    </div>
                    <h4>{item.title}</h4>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          
        </Container>
      </section>
    </>
  );
}
