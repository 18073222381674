import React from "react";
import { Container, Row, Col, Image, CardBody } from "react-bootstrap";
import Header from "../InnerHeader";
import headerbg from "../../img/contact-bg.jpeg";
import software from "../../img/services/softwaredevelopment.svg";

export default function Software() {
  return (
    <>
      <Header backgroundImage={headerbg} title="Software Development" />
      <section>
        <Container>
          <Row className="justify-content-center align-items-center">
            <Col xs={12} md={6}>
              <h2>
              Building Your Software Solutions
              </h2>
              <p>
                Softagics is specializes in crafting innovative solutions to meet your specific needs. With a focus on mobile apps, web development, enterprise solutions, and e-commerce, their team of experts can guide you through the entire development process, from concept to launch.
              </p>
            </Col>
            <Col xs={12} md={6}>
              <Image src={software} fluid alt="" />
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h5>
              Here's what Softagics can offer you:
              </h5>
              <ul>
                
<li><b>Custom Software Development:</b> We craft bespoke software solutions tailored to your specific requirements and workflows.</li>
<li><b>Mobile App Development:</b> We specialize in creating native apps for both iOS and Android platforms. Our focus is on user-centric design and seamless functionality, ensuring your app provides an exceptional user experience.</li>
<li><b>Web App Development:</b> Our web app development services go beyond just building websites. We create robust and scalable web applications that allow you to streamline operations, enhance customer engagement, and elevate your online presence.</li>
<li><b>UI/UX Design:</b> We believe in the power of intuitive design. Our UI/UX design experts create user interfaces that are not only aesthetically pleasing but also easy to navigate and use. This ensures a seamless user experience that keeps your customers engaged and coming back for more.</li>
<li><b>Graphic Design:</b> From eye-catching logos and branding materials to captivating social media graphics and website visuals, our graphic designers help you establish a strong visual identity that sets you apart.</li>
<li><b>Internet Marketing:</b> In today's digital world, a strong online presence is crucial. Our internet marketing specialists leverage proven strategies to increase your website traffic, brand awareness, and ultimately, your sales.</li>
<li><b>Ecommerce Solutions:</b> Looking to tap into the ever-growing online market? We develop user-friendly and secure ecommerce platforms that allow you to sell your products or services online seamlessly.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={12}>
              <h5>Softagics is committed to:</h5>
              <ul>
                <li><b>Innovation:</b> They use the latest technologies and methodologies to create cutting-edge solutions.</li>
<li><b>Simplicity:</b> They believe in creating software that's easy to use and manage.</li>
<li><b>Client Satisfaction:</b> They work closely with you to understand your needs and deliver a solution that exceeds your expectations.</li>

              </ul>
              
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
